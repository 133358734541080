<template>
  <Swiper
    :modules="modules"
    :loop="true"
    slides-per-view="auto"
    :simulateTouch="false"
    :space-between="15"
    :speed="600"
    class="testimonials-swiper relative w-full !pb-[65px] md:!pb-20px"
    :class="isSwiperInitialized ? 'visible' : 'invisible'"
    @init="handleInit"
  >
    <SwiperSlide v-for="(testimonial, idx) in data" :key="idx">
      <div class="flex h-full flex-col items-start justify-between bg-neutral-50 p-5 pb-7 md:p-8">
        <div>
          <Icon class="mb-8 md:mb-12 w-10px h-10px" name="google" />
          <div
            class="mb-8 text-lg md:text-[1.375rem] md:leading-8 line-clamp-[7]"
            v-html="testimonial.body"
          />
        </div>
        <div>
          <div class="mb-4 md:mb-[1.375rem]">
            <Icon
              v-for="(item, index) in 5"
              :key="item"
              class="ml-2 first:ml-0 w-7 h-7"
              name="star"
              :color="colors.yellow"
              :class="{ 'opacity-20': index >= testimonial.rating }"
            />
          </div>
          <p class="text-lg font-medium md:text-[1.375rem] md:leading-[1.1] pb-2 md:pb-5">
            {{ testimonial.name }}
          </p>
        </div>
      </div>
    </SwiperSlide>
    <SwiperNav class="absolute bottom-0 left-0 z-1" />
  </Swiper>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { A11y } from 'swiper/modules'
import { TestimonialRecord } from '~/types/generated'
import colors from '#tailwind-config/theme/colors'

import 'swiper/css'

defineProps({
  data: {
    type: Array as PropType<TestimonialRecord[]>,
    default: () => []
  }
})

const modules = [A11y]
const isSwiperInitialized = ref(false)

function handleInit() {
  isSwiperInitialized.value = true
}
</script>

<style lang="postcss">
.testimonials-swiper {
  .swiper-slide {
    max-width: 285px;

    @screen sm {
      max-width: 336px;
    }

    @screen lg {
      max-width: 448px;
    }
  }
}
</style>
